<template>
  <div class="content-area address-form">
    <hero-bar>
      {{ title }}

      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{ 'is-loading': isLoading }"
        @click="save"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component>
        <form>
          <b-field :label="$t('shiftName')" horizontal>
            <b-input
              v-model="form.name"
              :placeholder="$t('shiftName')"
              required
              class="is-fullwidth"
            />
          </b-field>
          <b-field label="Tarih Aralığı" horizontal>
            <b-datepicker
              v-model="form.starts_at"
              placeholder="Başlangıç Tarihi"
              :month-names="$t('dateTimePicker.monthNames')"
              :day-names="$t('dateTimePicker.dayNames')"
              trap-focus
            />
            <b-datepicker
              v-model="form.ends_at"
              placeholder="Bitiş Tarihi"
              :month-names="$t('dateTimePicker.monthNames')"
              :day-names="$t('dateTimePicker.dayNames')"
              trap-focus
            />
          </b-field>
          <b-field :label="$t('description')" horizontal>
            <b-input
              v-model="form.description"
              :placeholder="$t('description')"
              required
              class="is-fullwidth"
            />
          </b-field>

          <hr>

          <b-field v-for="(day, index) in form.days" :key="index" :label="day.label" horizontal>
            <b-input
              v-model="form.days[index].from"
              placeholder="Başlangıç Saati (örnek: 09:00)"
              icon="clock"
            />
            <b-input
              v-model="form.days[index].to"
              placeholder="Bitiş Saati (örnek: 18:00)"
              icon="clock"
            />
          </b-field>

          <hr>

          <b-field label="Sürücüler" horizontal grouped>
            <b-autocomplete
              v-model="driver"
              field="name"
              :data="drivers"
              :placeholder="$t('chooseDriver')"
              :loading="driversLoading"
              @typing="getDrivers"
              @select="option => (selectedDriver = option)"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    <b>{{ props.option.name }}</b>
                    <br>
                    <small>
                      {{ props.option.phone | phone }}
                    </small>
                  </div>
                </div>
              </template>
            </b-autocomplete>
            <p class="control">
              <b-button label="Ekle" @click="addDriverToSelectedDrivers" />
            </p>
          </b-field>

          <b-field v-if="selectedDrivers.length" label="Seçilen Sürücüler" horizontal>
            <div class="field is-grouped is-grouped-multiline">
              <div v-for="sDriver in selectedDrivers" :key="sDriver.id" class="control">
                <div class="tags has-addons">
                  <span class="tag is-info">{{ sDriver.name }}</span>
                  <a class="tag is-delete" @click="selectedDrivers = selectedDrivers.filter(({ id }) => id !== sDriver.id)" />
                </div>
              </div>
            </div>
          </b-field>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import moment from 'moment'
import debounce from 'lodash/debounce'

/** const serializeDays = (days) => {
  return days.map(day => {
    return {
      from: day.from ? moment(day.from).format('HH:mm') : null,
      to: day.to ? moment(day.to).format('HH:mm') : null,
    }
  })
}

const unserializeDays = (formData, days) => {
  return formData.map((day, index) => {
    if (days[index]?.from) {
      day.from = moment(days[index].from, 'hh:mm').toDate()
    }

    if (days[index]?.to) {
      day.to = moment(days[index].to, 'hh:mm').toDate()
    }

    return day
  })
} **/

export default {
  components: {
    HeroBar,
    CardComponent,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isLoading: false,
      form: {
        id: null,
        name: '',
        starts_at: null,
        ends_at: null,
        description: '',
        days: [
          { label: 'Pazartesi', from: null, to: null },
          { label: 'Salı', from: null, to: null },
          { label: 'Çarşamba', from: null, to: null },
          { label: 'Perşembe', from: null, to: null },
          { label: 'Cuma', from: null, to: null },
          { label: 'Cumartesi', from: null, to: null },
          { label: 'Pazar', from: null, to: null },
        ],
      },
      driversLoading: false,
      driver: '',
      drivers: [],
      selectedDriver: null,
      selectedDrivers: [],
    }
  },
  computed: {
    isCreateMode () {
      return !this.id
    },
    title () {
      return this.isCreateMode ? this.$t('createShift') : this.$t('editShift')
    }
  },
  created () {
    document.title = this.title

    if (!this.isCreateMode) {
      this.show(this.id)
    }
  },
  mounted () {
    this.$store.commit('setAsideActiveMenuKey', 'shifts.index')
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  methods: {
    save () {
      return this.isCreateMode ? this.store() : this.update(this.id)
    },
    store () {
      this.isLoading = true

      const params = {
        ...this.form,
        starts_at: moment(this.form.starts_at).format('YYYY-MM-DD'),
        ends_at: moment(this.form.ends_at).format('YYYY-MM-DD'),
        // days: serializeDays(this.form.days),
        drivers: this.selectedDrivers.map(driver => driver.id)
      }

      this.$http.post('/shifts', params).then(() => {
        this.$buefy.toast.open({
          message: this.$t('created'),
          type: 'is-success',
          duration: 5000,
        })

        this.$router.push({ name: 'shifts.index' })
      }).catch(({ response }) => {
        const errors = this.$_.uniq(this.$_.flattenDeep(this.$_.values(response.data.errors))).join('<br>')

        this.$buefy.toast.open({
          message: `${this.$t('error')}:<br>${errors}`,
          type: 'is-danger',
          queue: false,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    update (shiftId) {
      this.isLoading = true

      const params = {
        ...this.form,
        starts_at: moment(this.form.starts_at).format('YYYY-MM-DD'),
        ends_at: moment(this.form.ends_at).format('YYYY-MM-DD'),
        // days: serializeDays(this.form.days),
        drivers: this.selectedDrivers.map(driver => driver.id),
      }

      this.$http.put('/shifts/' + shiftId, params).then(() => {
        this.$buefy.toast.open({
          message: this.$t('updated'),
          type: 'is-success',
          duration: 5000,
        })

        this.$router.push({ name: 'shifts.index' })
      }).catch(({ response }) => {
        const errors = this.$_.uniq(this.$_.flattenDeep(this.$_.values(response.data.errors))).join('<br>')

        this.$buefy.toast.open({
          message: `${this.$t('error')}:<br>${errors}`,
          type: 'is-danger',
          queue: false,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    show (shiftId) {
      this.showSpinner()
      this.isLoading = true

      this.$http.get('/shifts/' + shiftId).then(({ data }) => {
        this.form = {
          ...data.data,
          starts_at: moment(data.data.starts_at).toDate(),
          ends_at: moment(data.data.ends_at).toDate(),
          days: this.form.days.map((day, index) => ({ ...day, ...data.data.days[index] })),
          // days: unserializeDays(this.form.days, data.data.days),
        }
        this.selectedDrivers = data.data.drivers || []
      }).catch(() => {
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-warning',
        })

        this.$router.push({ name: 'shifts.index' })
      }).finally(() => {
        this.hideSpinner()
        this.isLoading = false
      })
    },
    getDrivers: debounce(function (name) {
      this.drivers = []

      if (!name.length) {
        return
      }

      this.driversLoading = true

      this.$http.get('/users', { params: { role: 4, status: 1, name } }).then(({ data }) => {
        this.drivers = this.$_.differenceBy(data.data, this.selectedDrivers, 'id')
      }).catch(() => {
        this.drivers = []
      }).finally(() => {
        this.driversLoading = false
      })
    }, 500),
    addDriverToSelectedDrivers () {
      if (!this.selectedDriver) {
        return
      }

      if (this.selectedDrivers.findIndex(driver => driver.id === this.selectedDriver.id) > -1) {
        this.$buefy.toast.open({
          message: 'Sürücü zaten eklenmiş.',
          type: 'is-warning',
          queue: false,
        })

        this.driver = ''
        this.selectedDriver = null

        return
      }

      this.selectedDrivers.push(this.selectedDriver)
      this.driver = ''
      this.drivers = []
      this.selectedDriver = null
    },
  },
}
</script>